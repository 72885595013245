<template>
  <div style="background-color: rgba(50,50,120,0.1); height: 100%;">
    <div class="py-12">
      <div class="text-center py-2">
        <img size="30" src="@/assets/logo-deitada.png" style="width: 280px;" />
      </div>
    </div>
    <v-card class="pa-4 ma-8 mx-auto" max-width="500">
      <div>
        <div class="font-weight-light text-center text-h5 pa-8">
          {{ this.email }}
        </div>
        <div v-if="finalizado" class="px-8 text-center">
          <div class="font-weight-bold pa-2 ">Senha alterada com sucesso!</div>
          <div class="pa-2">Você já pode logar com a nova senha!</div>
          <div class="pa-3">
            <v-btn color="primary" @click="$router.push('/aluno/login')"
              >Fazer login</v-btn
            >
          </div>
        </div>
        <div v-if="!finalizado" class="px-8 text-center">
          <v-form v-model="valid" ref="form">
            <v-text-field
              name="name"
              label="Nova senha"
              id="id"
              :type="passwordView"
              :rules="validSenha"
              v-model="novaSenha"
            ></v-text-field>
            <v-text-field
              name="name"
              label="Confirmar nova senha"
              id="id"
              :type="passwordView"
              :rules="validSenhaConfirm"
              v-model="confirmarNovaSenha"
            ></v-text-field>
          </v-form>
        </div>
        <div v-if="!finalizado" class="action pa-4 text-center">
          <v-row>
            <v-col cols="4">
              <v-btn
                color="rgba(50,50,50,1)"
                large
                solo
                block
                outlined
                @click="setPasswordView()"
              >
                <v-icon
                  color="rgba(5,5,5,1)"
                  :disabled="!passwordView == 'text'"
                  >mdi-eye</v-icon
                >
              </v-btn>
            </v-col>
            <v-col cols="8">
              <v-btn
                @click="concluirProcesso()"
                color="primary"
                block
                large
                :loading="loading"
                :disabled="!valid"
                >Alterar a senha</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <div
      class="blockquack pa-5 mt-3 text-center"
      style="color: rgba(150,150,150,1)"
    >
      <div>
        Caso deseje manter a senha atual, basta sair dessa pagina
      </div>
      <div class="pa-3">
        <v-btn
          color="primary"
          outlined
          depressed
          @click="$router.push('/site')"
        >
          <v-icon size="20"> mdi-arrow-left-circle </v-icon>
          <span class="pa-3 d-inline-block">Voltar para o site</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import serviceAuth from "@/middleware/userAuth";
export default {
  data() {
    return {
      finalizado: false,
      loading: false,
      email: "",
      codigo: "",
      valid: false,
      novaSenha: "",
      confirmarNovaSenha: "",
      passwordView: "password",
      validSenha: [
        v => !!v || "Requer senha",
        v => v.length > 7 || "Senha é deve ser maior que 8"
      ],
      validSenhaConfirm: [
        v => !!v || "Requer senha",
        v => v.length > 7 || "Senha é deve ser maior que 8"
      ]
    };
  },
  watch: {
    novaSenha() {
      this.$refs.form.validate();
    },
    confirmarNovaSenha() {
      this.$refs.form.validate();
    }
  },
  methods: {
    concluirProcesso() {
      this.$refs.form.validate();
      var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (!regex.test(this.novaSenha) || !regex.test(this.confirmarNovaSenha)) {
        this.$root.errorModal(
          "Senha deve conter 8 digitos sendo numeros e letras"
        );
        return false;
      }
      if (!this.valid) {
        this.$root.errorModal(
          "O formulário está inválido, verifique as senhas."
        );
        this.loading = false;
        return false;
      }
      if (this.novaSenha !== this.confirmarNovaSenha) {
        this.$root.errorModal(
          "As senhas não estão iguais, verifique novamente"
        );
        this.loading = false;
        return false;
      }
      this.loading = true;
      const payload = {
        codigo: this.codigo,
        email: this.email,
        novaSenha: this.novaSenha
      };
      serviceAuth
        .alterarSenhaEsquecida(payload)
        .then(resp => {
          this.$root.sucessoModal(resp.data);
          this.finalizado = true;
          this.loading = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    setPasswordView() {
      if (this.passwordView == "password") {
        this.passwordView = "text";
      } else {
        this.passwordView = "password";
      }
    }
  },
  mounted() {
    this.email = this.$route.params.email;
    this.codigo = this.$route.params.codigo;
  }
};
</script>
